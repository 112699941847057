<template>
  <div class="account">
    <!-- register_annual_subheader -->
    <section id="sub_header_box" class="manual">
      <div class="w1300">

        <div class="sub_header_description txt-dark_gray">

          <div v-html="wordFromChairman"></div>

          <div class="info_spread" @click="toggleBottomInfo">
            <div class="top">
              <h4 class="txt-dark_gray txt-bold">特別感謝名單</h4>
              <div class="arrow" :class="{active: topActive}">
                <img src="@/statics/img/about/icon_arrow.svg" alt="">
              </div>
            </div>
            <VueSlideToggle class="bottom" :open="open" :duration="500">
              <div id="text_editor" v-html="computedSpecialThanks"></div>
            </VueSlideToggle>
          </div>

          <img class="heart_bg" src="@/statics/img/index/icon_heart.svg" alt="">

        </div>
      </div>
    </section>

    <section id="manual">
      <div class="w1300 manual_container">

        <div class="manual_top">
          <div class="main_title_box">
            <h3 class="main_title txt-bold">醫師手冊</h3>
            <div class="img_box">
              <img src="@/statics/img/index/icon_title2.svg" alt="">
            </div>
          </div>

          <div class="filter">
            <form action="" method="" @submit.prevent>
              <div class="time_filter">
                <!-- 透過關鍵字篩選 -->
                <div class="input-wrap">
                  <p class="small txt-bold txt-dark_gray">關鍵字搜尋</p>
                  <input
                    class="search"
                    type="text"
                    name="key"
                    v-model.trim="placeHolder"
                    @keyup.enter="updateKeyword"
                  >
                  <button type="submit" class="p" @click="updateKeyword">查詢</button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div class="card_box">
          <router-link
            :to="`/account/manual/pdf?id=${data.uuid}`"
            v-for="data in dataArray"
            :key="data.uuid"
            class="card_item"
          >
            <h4 class="card_title txt-bold">{{ data.title }}</h4>
            <ul class="card_list">
              <li
                class="small"
                v-for="(value, key) in data.json"
                :key="key"
              >
                {{ value }}
              </li>
            </ul>
            <div class="card_tag small">查看</div>
          </router-link>
        </div>

        <Pagination
          :pageButton="pageButton"
          :page="params.page"
          :totalPage="totalPage"
          @updatePage="updatePage"
        />

      </div>
    </section>
  </div>
</template>

<script>
/** Library */
import { VueSlideToggle } from 'vue-slide-toggle';

/** Components */
import Pagination from '@/components/Pagination.vue';

/** Functions */
import { articleIndex } from '@/lib/http';
import { nl2br } from '@/lib/public';

export default {
  name: 'AccountManualIndex',
  data() {
    return {
      /** Static Data */
      open: false,
      topActive: false,
      wordFromChairman: `
        <p>居家醫療照護整合計畫目的之一是提供病人整合性之全人照護，居家患者因失能或疾病特殊導致外出就醫不便，相對的，
          醫師必須對於各項用藥要有一定程度了解，第一版醫師手冊統整了居家醫療中常見疾病及用藥，希望能幫助醫師為患者提供服務。
          <br>居家醫療醫師手冊由副理事長 楊昆澈醫師總編輯，在此，本醫學會感謝編制及審稿居家醫療醫師手冊之醫師群。
          <br>本次為第一次出版居家醫療醫師手冊，以後每三年內更改版本乙次，除已編之疾病將再一一增加新內容。
          <br>
          <br>理事長 郭啟昭 109.5.31
        </p>`,
      specialThanksList: `
        <p>
          編輯醫師:
          <br> 台大醫院 張哲瑞醫師
          <br> 台大醫院 蘇柏維醫師
          <br> 台大醫院 郭芳慈醫師
          <br> 台大醫院北護分院 李伊真醫師
          <br> 台大醫院北護分院 李怡萱醫師
          <br> 台大醫院北護分院 林怡妏醫師
          <br> 台大醫院北護分院 胡惠姝護理師
          <br> 台大醫院雲林分院 葉忻瑜醫師
          <br> 中國醫藥大學附設醫院 陳宗伯醫師
          <br>
          <br> 審稿醫師:
          <br> 台灣居家醫療醫學會 郭啓昭理事長
          <br> 台灣居家醫療醫學會 楊昆澈副理事長
          <br> 台灣居家醫療醫學會 傅華國秘書長
          <br> 台大醫院北護分院 林怡妏醫師
          <br> 台大醫院北護分院 蔡欣熹醫師
        </p>`,
      placeHolder: '',
      pageButton: 5,

      /** Search Params */
      params: {
        type: 'download',
        keyword: '',
        limit: 10,
        page: 1,
      },

      /** Axios Data */
      specialThanks: '',
      totalPage: 1,
      dataArray: [],
    };
  },
  computed: {
    computedSpecialThanks() {
      return nl2br(this.specialThanks);
    },
    // Pagination
    // dataByKeyword() {
    //   if (this.keyword) {
    //     return this.dataArray.filter((data) => {
    //       const testInfo = [];
    //       for (let i = 0; i < data.infoList.length; i += 1) {
    //         if (data.infoList[i].content.includes(this.keyword)) {
    //           testInfo.push(data.infoList[i]);
    //         }
    //       }
    //       return data.title.includes(this.keyword)
    //       || testInfo.length > 0;
    //     });
    //   }
    //   return this.dataArray;
    // },
  },
  methods: {
    /**
     * 收合感謝名單資訊
     */
    toggleBottomInfo() {
      this.topActive = !this.topActive;
      this.open = !this.open;
    },
    /**
     * API Call: Article Index
     */
    filterDataInit(pageValue = 1) {
      articleIndex({
        ...this.params,
        page: pageValue,
      }).then((result) => {
        if (Object.keys(result).length > 0) {
          this.specialThanks = result.info;
          this.totalPage = result.pageCount;
          this.dataArray = result.data;
          this.params.page = pageValue;
          this.$router.replace({
            query: {
              keyword: this.params.keyword,
              num: pageValue,
            },
          });
        }
      });
    },
    filterData(pageValue = 1) {
      articleIndex({
        ...this.params,
        page: pageValue,
      }).then((result) => {
        if (Object.keys(result).length > 0) {
          this.specialThanks = result.info;
          this.totalPage = result.pageCount;
          this.dataArray = result.data;
          this.params.page = pageValue;
          this.$router.push({
            query: {
              keyword: this.params.keyword,
              num: pageValue,
            },
          });
        }
      });
    },
    // Pagination
    updatePage(value) {
      this.filterData(value);
    },
    updateKeyword() {
      this.params.keyword = this.placeHolder;
      this.filterData();
    },
  },
  components: {
    VueSlideToggle,
    Pagination,
  },
  created() {
    /**
     * 若網址帶參數的話，則覆蓋預設參數
     */
    const routeQuery = this.$route.query;
    if (routeQuery.keyword) {
      this.params.keyword = routeQuery.keyword;
    }
    if (routeQuery.num) {
      this.params.page = parseInt(routeQuery.num, 10);
    }
    this.filterDataInit(this.params.page);
  },
};
</script>
